import React from 'react';
import 'whatwg-fetch';

import { VideoSlide } from 'components/shared/video-slide';
import { CollectionSlide } from '../components/slide/collection-slide';
import { Video, Collection, CollectionsResponse, csRequest, csResponse, ResponsiveOptions } from '../types/content-strip';
import { isTouchDevice } from 'scripts/utils/isTouchDevice';

export const getResponsiveOptions = (contentType: string): ResponsiveOptions => {
  const threeUp = {
    max: 3,
    breakpoints: {
      767: { perPage: 2 },
    }
  };
  const fourUp = {
    max: 4,
    breakpoints: {
      767: { perPage: 2 },
      1023: { perPage: 3 },
    }
  };
  // mobile (touch) gets the 3-up layout by default
  if (isTouchDevice()) {
    return threeUp;
  } else {
    // only desktop (non-touch) can get the 4-up layout
    if (contentType === 'extras') {
      return document.querySelector('.four-up-extras') ? fourUp : threeUp;
    } else {
      return threeUp;
    }
  }
};

// This is a helper function to quickly calculate how many slides are up at a given breakpoint, for a given content type.
export function calcNumSlidesUp(
  contentType: string,
  windowWidth?: number
): number {
  let numSlidesUp = 3; // arbitrary - safe bet

  // we specifically indicate 4-up extras with a class when there are enough other content strips to place it safely beneath a vertical ad.
  if (contentType === 'extras' && document.querySelector('.four-up-extras')) {
    numSlidesUp = windowWidth >= 1024 ? 4 : windowWidth >= 768 ? 3 : 2;
  } else {
    // otherwise, content strips should follow the rule of 3-up at large, 2-up at small screens
    numSlidesUp = windowWidth >= 768 ? 3 : 2;
  }

  return numSlidesUp;
}

export const createVideoSlides = (videos: Video[], includeOverTitle: boolean | null = null): Array<React.ReactElement> => {
  // RWEB-6515 old browsers, which do not support .fetch, were generating a lot of noise
  // in this function, as it's used in a lot of places.
  // Adding the check for videos being defined and that it's not 0 in length
  // mostly as a way to quiet those errors.
  if (videos && videos.length > 0){
    return videos.map((video) => {
      return (
        <VideoSlide
          key={video.cid}
          video={video}
          includeOverTitle={includeOverTitle}
        />
      );
    });
  }
};

export const createCollectionSlides = (collectionsResponse: CollectionsResponse): Array<React.ReactElement> => {
  const collections = collectionsResponse.content;
  return collections.map((collection: Collection) => {
    return (
      <CollectionSlide
        key={collection.cid}
        collection={collection}
        titleLink={collectionsResponse.title_link}
      />
    );
  });
}

export function buildRequest(request: csRequest, endpoint: string): string {
  switch (endpoint) {
    case 'all-season-episodes':
      return `/${request.base}/${request.show_slug}/${endpoint}/?start=${request.start}&limit=${request.limit}`;

    case 'seasons-list':
      return `/${request.base}/${request.show_slug}/${endpoint}/`;

    case 'episodes':
      return `/${request.base}/${request.show_slug}/seasons/${request.season_id}/${endpoint}/?start=${request.start}&limit=${request.limit}`;

    case 'show-specials':
      return `/${request.base}/${request.show_slug}/${endpoint}/?start=${request.start}&limit=${request.limit}`;

    case 'season-extras':
      return `/${request.base}/${request.show_slug}/seasons/${request.season_id}/${endpoint}/?start=${request.start}&limit=${request.limit}`;

    case 'show-extras':
    case 'franchise-extras':
      return `/${request.base}/${request.show_slug}/${endpoint}/?start=${request.start}&limit=${request.limit}`;

    case 'video-extras':
      return `/${request.base}/${request.video_slug}/extras-json/`;

    case 'new-videos':
    case 'passport-videos':
    case 'local-videos':
    case 'popular-videos':
    case 'films-from-ken-burns':
      return `/${request.base}/${request.endpoint}/`;

    default:
      throw new Error(`The requested endpoint ${endpoint} was not recognized`);
  }
}

// RequestInfo and Response are types defined by whatwg-fetch
export const fetchData = (request: RequestInfo): Promise<csResponse | CollectionsResponse> => {
  return fetch(request, { method: 'GET'})
    .then((response: Response) => {
      // we want to throw an error for not-technically-error responses like 404s
      if (!response.ok) {
        const fetchError = `${response.status}: ${response.statusText}`
        throw new Error(fetchError);
      }
      return response.json();
    });
}

// helper function to cut down on checking existence and length of content
export function hasContent(rawResult: csResponse): boolean {
  return rawResult && rawResult.content && rawResult.content.length > 0;
}
