import { initializeShowRowPreviews } from 'components/show-row/show-row-preview/show-row-preview';
import { setupImageErrorHandler } from 'scripts/utils/setupImageErrorHandler';

interface CacheExpectations {
  showsList?: HTMLElement;
  loadMoreButton?: HTMLButtonElement;
  showListImages?: NodeListOf<HTMLImageElement>
}

const cache: CacheExpectations = {};

const setupCache = () => {
  cache.showsList = document.querySelector('.shows-list');
  cache.loadMoreButton = document.querySelector('.shows-list__load-more');
  cache.showListImages =
    cache.showsList &&
    cache.showsList.querySelectorAll('.shows-list__item__image');
};

const addEventListeners = () => {
  if (cache.loadMoreButton) {
    cache.loadMoreButton.addEventListener('click', showMorePosters);
    cache.loadMoreButton.addEventListener('mouseup', function () {
      this.blur();
    }); // remove focus after click; no effect for keyboard focus
  }
};

/**
 * On click of 'load more' button, displays an additional set of posters.
 */
const showMorePosters = () => {
  // find the array of poster elements with 'is-hidden' class
  const hiddenPosters = cache.showsList.querySelectorAll('.is-hidden');
  const hiddenLength = hiddenPosters.length;
  const numberOfPostersToShowAtATime = 24;

  hiddenPosters.forEach((poster, index) => {
    // only show up to the next 24 posters (indices 0-23)
    if (index >= numberOfPostersToShowAtATime) {
      return false;
    }

    poster.classList.remove('is-hidden');
  });

  // if all the posters are now shown, hide the 'load more' button
  if (hiddenLength <= numberOfPostersToShowAtATime) {
    cache.loadMoreButton.classList.add('is-hidden');
  }
};

/**
 * Initializes component.
 */
const init = (): void => {
  setupCache();
  addEventListeners();
  initializeShowRowPreviews();

  // init the responsive images plugin
  if (cache.showsList) {
    setupImageErrorHandler(cache.showListImages, true);

    // show 'load more' button if hidden posters exist
    if (cache.showsList.querySelectorAll('.is-hidden').length > 0) {
      cache.loadMoreButton.classList.remove('is-hidden');
    }
  }
};

export { init };
