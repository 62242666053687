import React from 'react';
import { SplideSlide } from '@splidejs/react-splide';

import { CollectionSlideProps } from '../../types/content-strip';

export const CollectionSlide: React.FC<CollectionSlideProps> = (props) => {
  const { collection, titleLink } = props;
  return (
    <SplideSlide key={collection.cid}>
      <div className="collection">
        <a
          href={`${titleLink}${collection.slug}/`}
          className="collection__image-link fallback-image__container fallback-image__container--gray"
        >
          <img
            sizes={`(max-width: 479px) calc(100vw - 48px),
              (max-width: 767px) calc(50vw - 24px),
              (max-width: 1023px) calc(33.33vw - 42px),
              calc(25vw - 40px)`}
            srcSet={`${collection.image}?crop=284x160&format=jpg 284w,
              ${collection.image}?crop=359x201&format=jpg 359w,
              ${collection.image}?crop=447x250&format=jpg 447w,
              ${collection.image}?crop=568x320&format=jpg 568w,
              ${collection.image}?crop=718x402&format=jpg 718w,
              ${collection.image}?crop=894x500&format=jpg 894w`}
            alt={collection.title}
            className="collection__image img-responsive"
            src={`${collection.image}?crop=359x201&?format=jpg`}
            width="359"
            height="201"
            loading="lazy"
          />
        </a>
        <div className="collection__details">
          <h3 className="collection__title">
            <a
              href={`${titleLink}${collection.slug}/`}
              className="collection__title-link"
              data-gtm-position="1"
            >
              {collection.title}
            </a>
          </h3>
          <p className="collection__description">{collection.description_short}</p>
        </div>
      </div>
    </SplideSlide>
  );
};
export default CollectionSlide;
