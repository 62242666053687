import React, { useState, useEffect } from 'react';

import { SeasonSelect } from './season-select';
import { SeasonButtons } from './season-buttons';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { SeasonPickerProps } from '../../types/content-strip';

export const SeasonPicker: React.FC<SeasonPickerProps> = (props) => {
  const { contentType, context, selectedSeason, setSelectedSeason, seasonsList, uniqueId } = props;
  const width = useWindowWidth();
  const shouldDisplayButtons = width >= 1024 && context === 'show';

  const [latestSeasons, setLatestSeasons] = useState([]);
  const [remainderSeasons, setRemainderSeasons] = useState([]);

  const handleChange = (newSeason: number) => {
    setSelectedSeason(newSeason);
  }

  // on initial load, determine if seasons will be split between buttons and select at higher breakpoints
  useEffect(() => {
    // if only 2 or 3 seasons are available, we omit the Season Select at higher breakpoints
    if (seasonsList.length < 4) {
      setLatestSeasons(seasonsList);
      setRemainderSeasons(null);
    } else {
      const firstThree = seasonsList.slice(0, 3);
      const otherSeasons = seasonsList.slice(3);
      setLatestSeasons(firstThree);
      setRemainderSeasons(otherSeasons);
    }
  }, [seasonsList]);

  return (
    <div className="content-strip__season-picker">
      <SeasonButtons
        uniqueId={uniqueId}
        contentType={contentType}
        selectedSeason={selectedSeason}
        seasonsList={latestSeasons}
        handleSeasonChange={handleChange}
      />
      <SeasonSelect
        uniqueId={uniqueId}
        contentType={contentType}
        selectedSeason={selectedSeason}
        handleSeasonChange={handleChange}
        seasonsList={shouldDisplayButtons ? remainderSeasons : seasonsList}
        alsoDisplayingButtons={shouldDisplayButtons}
      />
    </div>
  );
}
