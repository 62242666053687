import React from 'react';

import { SeasonButtonsProps } from '../../types/content-strip';

// Buttons to display All Seasons (for episodes only), plus latest three seasons, at larger window widths (1024+)
export const SeasonButtons: React.FC<SeasonButtonsProps> = (props) => {
  const { contentType, selectedSeason, seasonsList, handleSeasonChange, uniqueId } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSeason = parseInt(event.target.value, 10);

    handleSeasonChange(newSeason); // update the season in parent component
  }

  if (!seasonsList) return null;

  return (
    <ul className="content-strip__season-buttons">
      {seasonsList.map((season, index) => {
        let seasonName = `Season ${season.ordinal}`;
        if (!season.ordinal && contentType === 'extras') {
          seasonName = 'More Extras';
        } else if (!season.ordinal && contentType === 'episodes') {
          seasonName = 'All'
        }
        return (
          <li className="content-strip__season-button" key={index}>
            <input
              className="content-strip__season-input"
              type="radio"
              value={season.ordinal}
              id={`${uniqueId}-season-${season.ordinal}--${contentType}`}
              checked={season.ordinal === selectedSeason}
              onChange={handleChange}/>
            <label
              className="content-strip__season-label"
              htmlFor={`${uniqueId}-season-${season.ordinal}--${contentType}`}
            >
              {seasonName}
            </label>
          </li>
        );
      })}
    </ul>
  );
};
