import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Cookies from 'js-cookie';

import SingleSpecial from '../../components/slide/single-special';
import ContentStrip from '../../content-strip';
import LoadingIndicator from 'components/shared/loading-indicator';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { SpecialsStripProps, Video, csRequest, csResponse } from '../../types/content-strip';
import { calcNumSlidesUp, createVideoSlides, getResponsiveOptions } from '../../utils/utils';
import { getSlides } from './specials-data';

const SpecialsStrip: React.FC<SpecialsStripProps> = (props) => {
  const { context, currentContent, initialVideos, showSlug, videoType } = props;
  const contentType = 'specials';
  const seeAllUrl = `/show/${showSlug}/specials/`;
  const carouselTitle = context === 'show' || videoType !== 'special' ? 'Specials' : 'More Specials';
  const width: number = useWindowWidth();
  const responsiveOptions = getResponsiveOptions(contentType); // defines 2-up/3-up/4-up layout breakpoints
  const requestParams: csRequest = {
    base: 'show', // specials content strip always makes calls to /show/
    show_slug: showSlug,
    start: 0,
    limit: 24,
    station_id: Cookies.get('pbsol.station_id') || null,
  };

  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [slides, setSlides] = useState([]);
  const [numSlidesUp, setNumSlidesUp] = useState(calcNumSlidesUp(contentType, width)); // gets the number of slides up based on responsiveoptions and initial width
  const [singleSpecial, setSingleSpecial] = useState<Video | null>(null);

  // use the initial data passed in from template for first render
  useEffect(() => {
    setIsFetching(true);
    const filteredResponse = initialVideos.content.map(item => {
      return {
        ...item,
        isCurrentContent: item.slug === currentContent
      };
    });
    if (filteredResponse.length === 1 && context === 'show') {
      setSingleSpecial(filteredResponse[0]);
    } else {
      setSingleSpecial(null);
    }
    const slides = createVideoSlides(filteredResponse);
    setSlides(slides);
    setNextPageUrl(initialVideos.meta.links.next);
    setIsFetching(false);
  }, [context, currentContent, initialVideos.content, initialVideos.meta.links.next]);

  // when a change to the browser width is detected, alter the number of slides up accordingly
  useEffect(() => {
    setNumSlidesUp(calcNumSlidesUp(contentType, width));
  }, [width]);

  const fetchMoreSlides = () => {
    if (!nextPageUrl) return Promise.resolve(null);
    const newStart = new URL(nextPageUrl).searchParams.get('start');
    const newRequest = {
      ...requestParams,
      start: newStart
    }
    let newSlides = null;
    newSlides = getSlides(newRequest).then((newResponse: csResponse) => {
      setNextPageUrl(newResponse.meta.links.next);
      return createVideoSlides(newResponse.content);
    });
    return newSlides;
  };

  if (singleSpecial && context === 'specials') {
    // don't show the single special block if we're already on that special's playback page
    return null;
  } else if (singleSpecial) {
    return (
      <SingleSpecial featuredVideo={singleSpecial} />
    );
  }

  if (slides && slides.length > 0) {
    return (
      <ContentStrip
        carouselTitle={carouselTitle}
        contentType={contentType}
        fetchMoreSlides={fetchMoreSlides}
        numSlidesUp={numSlidesUp}
        responsiveOptions={responsiveOptions}
        isFetching={isFetching}
        seeAllUrl={seeAllUrl}
        showSlug={showSlug}
        slides={slides}
        videoSlug={currentContent}
        videoType={videoType}
      />
    )
  } else return (
    <LoadingIndicator addClass="content-strip__loading-indicator" />
  );
};

const init = (): void => {
  // Check that the DOM element exists before trying to mount the component.
  const mountPoint = document.getElementById('specials-strip');
  const jsonNode = document.getElementById('content-strip-data');
  if (mountPoint && jsonNode) {
    // take any props from template data attributes and json_data object
    const contentStripData = JSON.parse(jsonNode.textContent);
    const props: SpecialsStripProps = JSON.parse(JSON.stringify(mountPoint.dataset));
    const specialsData = contentStripData['specials_data'];
    props.initialVideos = specialsData['default_specials'];
    const root = createRoot(mountPoint!);
    root.render(<SpecialsStrip {...props} />);
  }
};

export { init };
