import { csRequest, csResponse, Season } from '../../types/content-strip';
import { buildRequest, fetchData } from '../../utils/utils';

function getAllSeasonsEpisodes(requestParams: csRequest) {
  const allSeasonsEpisodessRequest = buildRequest(requestParams, 'all-season-episodes');
  return fetchData(allSeasonsEpisodessRequest).then((rawAllSeasonsEpisodesResponse: csResponse) => {
    return rawAllSeasonsEpisodesResponse;
  });
}

export function getSlides(season: Season, requestParams: csRequest): Promise<csResponse> {
  // if season.ordinal is 0, we're looking at All Episodes
  if (!season || !season.ordinal) {
    return getAllSeasonsEpisodes(requestParams).then((allSeasonsEpisodesResponse: csResponse) => {
      return allSeasonsEpisodesResponse;
    });
  } else {
    // otherwise, get the episodes from the indicated season
    return getSeasonLevelEpisodes(season, requestParams).then((seasonLevelEpisodesResponse: csResponse) => {
      return seasonLevelEpisodesResponse;
    });
  }
}

function getSeasonLevelEpisodes(season: Season, requestParams: csRequest) {
  const seasonRequest = {
    ...requestParams,
    season_id: season.cid
  }
  const seasonLevelEpisodesRequest = buildRequest(seasonRequest, 'episodes');
  return fetchData(seasonLevelEpisodesRequest).then((seasonLevelEpisodes: csResponse) => {
    return seasonLevelEpisodes;
  });
}
