import React, { useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import { SplideSlide } from '@splidejs/react-splide';

import { customEvents } from 'scripts/custom-events';
import VideoPopover from './video-slide-popover';
import { VideoSlideProps } from './video-slides-types';
import { getUserId } from 'scripts/utils/getUserId';
import { getVideoPercentageWatched } from 'scripts/utils/getVideoPercentageWatched';

export const VideoSlide: React.FC<VideoSlideProps> = (props) => {
  const {
    className,
    displayContinueWatching,
    includeOverTitle,
    percentageWatched: percentageWatchedProp,
    video,
  } = props;

  const [popover, setPopover] = useState(<div></div>);
  const [userIsPassport, setUserIsPassport] = useState(false);
  const [percentageWatched, setPercentageWatched] = useState(
    percentageWatchedProp ? percentageWatchedProp : null
  );
  const handleUserIsPassport = () => setUserIsPassport(true);
  const userId = getUserId();
  const avifEnabled = window.PBS_AVIF_ENABLED;

  const handleUserViewingHistory = () => {
    // if percentageWatched was passed as a prop (see initial useState value)
    // use that, and don't override it by looking at the viewer's saved
    // viewing history
    if (!percentageWatched) {
      setPercentageWatched(getVideoPercentageWatched(video.slug));
    }
  };

  useEffect(() => {
    // if the user is logged in
    // NOTE - this is a very important check - without it, viewing history
    // might appear for logged out users, which is creepy
    if (userId) {
      const storedViewingHistory = localStorage.getItem('userViewingHistory');
      // is there already history in local storage?
      if (storedViewingHistory) {
        // handle the viewing history
        handleUserViewingHistory();
      } else {
        // if the user is logged in and no stored viewing history exists,
        // set an event listener for the userViewingHistoryLoaded event
        // see get-user-viewing-history.ts
        window.addEventListener(
          customEvents.userViewingHistoryLoaded,
          handleUserViewingHistory
        );
        return () => {
          window.removeEventListener(
            customEvents.userViewingHistoryLoaded,
            handleUserViewingHistory
          );
        };
      }
    }
  });

  useEffect(() => {
    // we have to attach this event listener at this point because
    // it benefits popovers, but those are not instantiated until
    // the user has hovered over the slide
    window.addEventListener(customEvents.userIsPassport, handleUserIsPassport);
    return () => {
      window.removeEventListener(
        customEvents.userIsPassport,
        handleUserIsPassport
      );
    };
  });

  // on mouse over of a slide, set the Popover content. forwardRef.
  const handleHover = (e: React.SyntheticEvent<Element>) => {
    const target = e.currentTarget as HTMLElement;

    const hoveredItem = {
      ancestor: target.dataset.ancestor,
      title: target.dataset.title,
      cid: target.dataset.cid,
      type: target.dataset.videoType,
      slug: target.dataset.videoSlug,
    };

    setPopover(
      <VideoPopover
        video={video}
        parentVideo={hoveredItem}
        userIsPassport={userIsPassport}
      />
    );
  };

  const maxSize = `448px`;
  const summaryImage = (
    <React.Fragment>
      <picture>
        {/* Force the browser to load images at specified viewports */}
        {avifEnabled && (
          <source
            type="image/avif"
            sizes={`(min-width: 1024px) ${maxSize},
              (min-width: 768px) calc(33vw - 42px),
              calc(50vw - 32px)`}
            srcSet={`${video.image}?crop=218x122&format=avif 218w,
              ${video.image}?crop=280x157&format=avif 280w,
              ${video.image}?crop=384x215&format=avif 384w,
              ${video.image}?crop=448x250&format=avif 448w,
              ${video.image}?crop=480x270&format=avif 480w,
              ${video.image}?crop=767x432&format=avif 767w,
              ${video.image}?crop=960x540&format=avif 960w,
              ${video.image}?crop=1534x864&format=avif 1534w`}
          />
        )}
        <source
          type="image/webp"
          sizes={`(min-width: 1024px) ${maxSize},
              (min-width: 768px) calc(33vw - 42px),
              calc(50vw - 32px)`}
          srcSet={`${video.image}?crop=218x122&format=webp 218w,
              ${video.image}?crop=280x157&format=webp 280w,
              ${video.image}?crop=384x215&format=webp 384w,
              ${video.image}?crop=448x250&format=webp 448w,
              ${video.image}?crop=480x270&format=webp 480w,
              ${video.image}?crop=767x432&format=webp 767w,
              ${video.image}?crop=960x540&format=webp 960w,
              ${video.image}?crop=1534x864&format=webp 1534w`}
        />
        <img
          sizes={`(min-width: 1024px) ${maxSize},
              (min-width: 768px) calc(33vw - 42px),
              calc(50vw - 32px)`}
          srcSet={`${video.image}?crop=218x122&?format=jpg 218w,
              ${video.image}?crop=280x157&?format=jpg 280w,
              ${video.image}?crop=384x215&?format=jpg 384w,
              ${video.image}?crop=448x250&?format=jpg 448w,
              ${video.image}?crop=480x270&?format=jpg 480w,
              ${video.image}?crop=767x432&?format=jpg 767w,
              ${video.image}?crop=960x540&?format=jpg 960w,
              ${video.image}?crop=1534x864&?format=jpg 1534w`}
          alt={`Video thumbnail: ${video.ancestor_title} ${video.title}`}
          className="video-summary__image img-responsive image--needs-fallback"
          src={`${video.image}?crop=384x215&?format=jpg`}
          width="384"
          height="215"
          loading="lazy"
        />
      </picture>
      {video?.flags?.is_mvod && (
        <img
          className="pbs-passport-compass-rose"
          src={`${window.PBS_STATIC_URL}svg/pbs-passport-compass-rose--corner.svg`}
          alt="Passport"
          width="32"
          height="32"
        />
      )}
      {video && video.isCurrentContent && (
        <p className="video-summary__current">Now Showing</p>
      )}
    </React.Fragment>
  );

  // Don't include link to video or popover if this slide represents the current content
  // (if we're on that video's own video playback page)
  if (video && video.isCurrentContent) {
    return (
      <SplideSlide key={video.cid} className={className}>
        <div
          className="video-summary"
          data-ancestor={`${video.ancestor_title}`}
          data-cid={`${video.cid}`}
          data-title={`${video.title}`}
          data-video-type={`${video.video_type}`}
          data-video-slug={`${video.slug}`}
          onMouseOver={handleHover}
        >
          <div className="video-summary__link fallback-image__container fallback-image__container--gray ">
            {summaryImage}
          </div>
          <div className="video-summary__details">
            {includeOverTitle && (
              <p className="video-summary__over-title">
                <a
                  className="video-summary__over-title-link"
                  href={`/${video.ancestor_type}/${video.ancestor_slug}/`}
                >
                  {video.ancestor_title}
                </a>
              </p>
            )}
            <h3 className="video-summary__title">
              <a
                href={`/video/${video.slug}/`}
                className="video-summary__video-title-link"
                data-gtm-position="1"
              >
                {video.title}
              </a>
            </h3>
            <p className="video-summary__meta-data">{video.summary}</p>
          </div>
        </div>
      </SplideSlide>
    );
  }

  return (
    /* Carousel item container
      - wraps the slide item in a Tippy parent component, which launches the popover on mouse hover */
    <SplideSlide key={video.cid} className={className}>
      <Tippy
        aria={{
          content: null,
          expanded: false,
        }}
        className="popover--show-theme"
        content={popover}
        arrow={true}
        delay={[400, 200]}
        interactive={true}
        maxWidth={385}
        placement="left"
        theme="popover--show"
        touch={false}
        zIndex={4}
        appendTo={document.body}
      >
        <div
          className="video-summary"
          data-ancestor={`${video.ancestor_title}`}
          data-cid={`${video.cid}`}
          data-title={`${video.title}`}
          data-video-type={`${video.video_type}`}
          data-video-slug={`${video.slug}`}
          onMouseOver={handleHover}
        >
          <a
            href={`/video/${video.slug}/`}
            className={`video-summary__link fallback-image__container fallback-image__container--gray popover-trigger has-play-icon ${
              displayContinueWatching ? 'has-play-icon--visible' : ''
            }`}
          >
            {summaryImage}
            {displayContinueWatching && (
              <p className="video-summary__continue-watching-overlay">
                Continue Watching
              </p>
            )}
            {percentageWatched && (
              <>
                <progress
                  max="100"
                  value={percentageWatched}
                  className="viewing-history-progress"
                ></progress>
              </>
            )}
          </a>
          <div className="video-summary__details">
            {includeOverTitle && (
              <p className="video-summary__over-title">
                <a
                  className="video-summary__over-title-link"
                  href={`/${video.ancestor_type}/${video.ancestor_slug}/`}
                >
                  {video.ancestor_title}
                </a>
              </p>
            )}
            <h3 className="video-summary__title">
              <a
                href={`/video/${video.slug}/`}
                className="video-summary__video-title-link"
                data-gtm-position="1"
              >
                {video.title}
              </a>
            </h3>
            <p className="video-summary__meta-data">{video.summary}</p>
          </div>
        </div>
      </Tippy>
    </SplideSlide>
  );
};
export default VideoSlide;
