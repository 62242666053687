interface CacheExpectations {
  secondaryTextRevealBtn?: HTMLButtonElement;
  secondaryTextRevealBtnText?: HTMLSpanElement;
  secondaryText?: HTMLDivElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} setupCache
 */
const setupCache = () => {
  cache.secondaryTextRevealBtn = document.querySelector(
    '.supporting-text__reveal-btn'
  );
  cache.secondaryTextRevealBtnText = document.querySelector(
    '.supporting-text__reveal-btn__text'
  );
  cache.secondaryText = document.querySelector('.supporting-text--secondary');
};

const addEventListeners = () => {
  if (cache.secondaryTextRevealBtn && cache.secondaryText) {
    cache.secondaryTextRevealBtn.addEventListener('click', () => {
      cache.secondaryText.classList.toggle('is-collapsed');
      cache.secondaryTextRevealBtn.classList.toggle('is-open');

      if (cache.secondaryTextRevealBtnText.innerHTML == "Read more") {
        cache.secondaryTextRevealBtnText.innerHTML = "Read less";
      } else {
        cache.secondaryTextRevealBtnText.innerHTML = "Read more";
      }
    });
  }
};

/**
 * Initializes component.
 * @returns {function} _init
 */
const init = (): void => {
  setupCache();
  addEventListeners();
};

export { init };
