import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { arrowPath } from 'components/carousel-arrows/arrow-path';

interface PhotoGalleryCache {
  photoGalleryContainer?: HTMLElement;
  'gallery'?: Splide;
}

const cache: PhotoGalleryCache = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.photoGalleryContainer = document.querySelector(
    '.photo-gallery .carousel-container'
  );
};

/**
 * Creates photo gallery.
 */
const setupCarousel = () => {
  if (cache.photoGalleryContainer) {
    cache['gallery'] = new Splide(cache.photoGalleryContainer, {
      arrowPath: arrowPath,
      classes: {
        arrow: 'carousel__arrow',
        prev: 'splide__arrow--prev carousel-prev',
        next: `splide__arrow--next carousel-next`,
      },
      pagination: false,
      perPage: 1,
      speed: 600,
      type: 'loop',
    }).mount();
    addEventListeners();
  }
};

/**
 * Adds event listeners for important gallery interactions.
 */
const addEventListeners = () => {
  const images = cache.photoGalleryContainer.querySelectorAll(
    '.photo-gallery__link'
  );
  const closeButton = cache.photoGalleryContainer.querySelector(
    '.photo-gallery__close'
  );
  closeButton.addEventListener('click', () => handleCloseClick());
  cache.photoGalleryContainer.addEventListener('click', (e) => handleBackgroundClick(e));

  images.forEach((image) => {
    image.addEventListener('click', () => handleImageClick());
  });
};

/**
 * Opens the full-screen lightbox when image is clicked.
 */
const handleImageClick = () => {
  cache.photoGalleryContainer.classList.add('lightbox-open');
  document.documentElement.classList.add('has-visible-modal');
  cache['gallery'].refresh();
};

/**
 * Closes the full-screen lightbox when close button is clicked.
 */
const handleCloseClick = () => {
  cache.photoGalleryContainer.classList.remove('lightbox-open');
  document.documentElement.classList.remove('has-visible-modal');
  cache['gallery'].refresh();
};

/**
 * Determines whether or not to close lightbox when background is clicked.
 */
const handleBackgroundClick = (e: MouseEvent) => {
  if (
    e.target == cache.photoGalleryContainer &&
    cache.photoGalleryContainer.classList.contains('lightbox-open')
  ) {
    handleCloseClick();
  }
}

/**
 * Initializes component.
 */
const init = (): void => {
  // eslint-disable-next-line no-console
  setupCache();
  setupCarousel();
};

export { init };
