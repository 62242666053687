import { csRequest, csResponse, Season } from '../../types/content-strip';
import { buildRequest, fetchData } from '../../utils/utils';

function getShowLevelExtras(requestParams: csRequest) {
  const showLevelExtrasRequest = `/show/${requestParams.show_slug}/show-extras/?start=${requestParams.start}&limit=${requestParams.limit}`;
  return fetchData(showLevelExtrasRequest).then((rawShowLevelExtrasResponse) => {
    return rawShowLevelExtrasResponse;
  });
}

function getFranchiseLevelExtras(requestParams: csRequest) {
  const franchiseLevelExtrasRequest = `/franchise/${requestParams.show_slug}/franchise-extras/?start=${requestParams.start}&limit=${requestParams.limit}`;
  return fetchData(franchiseLevelExtrasRequest).then((rawFranchiseLevelExtrasResponse) => {
    return rawFranchiseLevelExtrasResponse;
  });
}

export function getSlides(season: Season, requestParams: csRequest): Promise<csResponse> {
  // if this is for "extras from an episode" or something
  if (!season) {
    return getVideoExtras(requestParams).then((videoExtrasResponse: csResponse) => {
      return videoExtrasResponse;
    });
  // if this is the franchise-level extras strip
  } else if (season.cid === 'franchise-extras') {
    return getFranchiseLevelExtras(requestParams).then((franchiseLevelExtrasResponse: csResponse) => {
      return franchiseLevelExtrasResponse;
    });
  // if the season indicated is in fact "more extras"/show-level:
  } else if (!season.ordinal) {
    return getShowLevelExtras(requestParams).then((showLevelExtrasResponse: csResponse) => {
      return showLevelExtrasResponse;
    });
  } else {
    // otherwise, get the extras from the indicated season
    return getSeasonLevelExtras(season, requestParams).then((seasonLevelExtrasResponse: csResponse) => {
      return seasonLevelExtrasResponse;
    });
  }
}

function getSeasonLevelExtras(season: Season, requestParams: csRequest) {
  const seasonRequest = {
    ...requestParams,
    season_id: season.cid
  }
  const seasonLevelExtrasRequest = `/show/${seasonRequest.show_slug}/seasons/${seasonRequest.season_id}/season-extras/?start=${seasonRequest.start}&limit=${seasonRequest.limit}`;
  return fetchData(seasonLevelExtrasRequest).then((seasonLevelExtras: csResponse) => {
    return seasonLevelExtras;
  });
}

function getVideoExtras(requestParams: csRequest) {
  const videoExtrasRequest = buildRequest(requestParams, 'video-extras');
  return fetchData(videoExtrasRequest).then((videoExtras: csResponse) => {
    return videoExtras;
  });
}
