import { csRequest, csResponse } from '../../types/content-strip';
import { buildRequest, fetchData } from '../../utils/utils'

export function getSlides(requestParams: csRequest): Promise<csResponse> {
  return getShowLevelSpecials(requestParams).then((showLevelSpecialsResponse: csResponse) => {
    return showLevelSpecialsResponse;
  });
}

function getShowLevelSpecials(requestParams: csRequest) {
  const showLevelSpecialsRequest = buildRequest(requestParams, 'show-specials');
  return fetchData(showLevelSpecialsRequest).then((rawShowLevelSpecialsResponse: csResponse) => {
    return rawShowLevelSpecialsResponse;
  });
}
