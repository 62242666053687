import React, { useState, useEffect, useRef } from 'react';
import { Splide } from '@splidejs/react-splide';

import LoadingIndicator from 'components/shared/loading-indicator';
import SeeAll from './components/see-all/see-all';
import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { ReactComponent as TitleCaretRight } from 'svg/pbs-right-caret.svg';
import { ContentStripProps, CollectionSlideProps } from './types/content-strip';
import { VideoSlideProps } from 'components/shared/video-slides-types';
import { slugify } from 'scripts/utils/slugify';

const ContentStrip: React.FC<ContentStripProps> = (props) => {
  const {
    carouselTitle,
    contentType,
    fetchMoreSlides,
    isFetching,
    numSlidesUp,
    responsiveOptions,
    seeAllUrl,
    slides,
  } = props;
  const splideRef = useRef(null);

  // set initial state
  const [currentIndex, setCurrentIndex] = useState(0); // start at index 0
  const [items, setItems] = useState(slides); // list of items in the content strip
  const [itemsRemaining, setItemsRemaining] = useState(slides.length - currentIndex - numSlidesUp); // items left to be scrolled (to the right)
  const [shouldDisplaySeeAllButton, setShouldDisplaySeeAllButton] = useState(false);


  // When the base list of slides changes (e.g. when selecting a new season),
  // update the list of items and number remaining to be scrolled.
  useEffect(() => {
    setItems(slides);
    setCurrentIndex(0);
    setItemsRemaining(slides.length - currentIndex - numSlidesUp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  // When the carousel moves, currentIndex is updated;
  // the number of items remaining should be updated accordingly.
  useEffect(() => {
    setItemsRemaining(items.length - currentIndex - numSlidesUp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  // We adjust the display of the carousel arrows and See All button
  // depending on the number of items remaining in the carousel.
  useEffect(() => {
    // When number of slides remaining in the carousel is low enough,
    // attempt to load more slides.
    if (itemsRemaining <= numSlidesUp && fetchMoreSlides) {
      fetchMoreSlides().then((newSlides: VideoSlideProps[] | CollectionSlideProps[]) => {
        if (newSlides) {
          // there is a big gnarly ts error here having to do with react portal, children, props, etc
          // @TODO sort this out
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setItems([...items, ...newSlides]);
          setShouldDisplaySeeAllButton(false);
        }
        if (itemsRemaining === 0) {
          // user is on the last page of slides with no more to be fetched
          setShouldDisplaySeeAllButton(true);
        }
      });
    } else {
      setShouldDisplaySeeAllButton(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsRemaining]);

  return (
    <div className={`${isFetching ? 'content-strip__is-loading' : 'content-strip__loaded'}`}>
      <div className="carousel__title-filters">
        <h2 className="carousel__title content-section-title" id={slugify(carouselTitle)}>
          <a
            href={seeAllUrl}
            className="content-section-title__link"
            data-gtm-action={contentType}
          >
            <span>{carouselTitle}</span>
            <TitleCaretRight />
          </a>
        </h2>
        {/* season buttons and filters render here */}
        {props.children}
      </div>
      {isFetching ? (
        <LoadingIndicator addClass="content-strip__loading-indicator" />
      ) : (
        <div className="content-strip__wrapper">
          <Splide
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onMoved={(splideInstance: any, newIndex: number, oldIndex: number) => {
              setCurrentIndex(newIndex);
              if (newIndex < oldIndex) {
                // if we're moving left, don't display the See All button
                setShouldDisplaySeeAllButton(false);
              }
            }}
            options={{
              arrowPath: arrowPath, // using our custom caret SVG in splide's built-in arrows
              breakpoints: responsiveOptions.breakpoints,
              classes: {
                arrow: 'carousel__arrow',
                prev: 'splide__arrow--prev carousel-prev',
                next: `splide__arrow--next carousel-next ${slides.length <= numSlidesUp ? 'is-hidden' : ''}`,
              },
              drag: true,
              gap: '16px',
              pagination: false,
              perPage: responsiveOptions.max,
              rewind: false,
              slideFocus: false,
            }}
            ref={splideRef}
          >
            {items}
          </Splide>
          {shouldDisplaySeeAllButton && slides.length > numSlidesUp && (
            <SeeAll
              contentType={contentType}
              seeAllUrl={seeAllUrl}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ContentStrip;
