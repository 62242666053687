type showHeroElementCache = {
  funderShort?: HTMLElement,
  funderButton?: HTMLButtonElement,
  funderLong?: HTMLElement,
}

const cache: showHeroElementCache = {};

const setupCache = () => {
  // funder message toggling elements
  cache.funderShort = document.querySelector('.funder-message--short');
  cache.funderButton = document.querySelector('.funder-message__button');
  cache.funderLong = document.querySelector('.funder-message--long');
};

// replace short funder message and "MORE" button with long funder message
const onFunderClick = () => {
  cache.funderShort && cache.funderShort.classList.add('is-hidden');
  cache.funderButton && cache.funderButton.classList.add('is-hidden');
  cache.funderLong && cache.funderLong.classList.remove('is-hidden');
}

const addEventListeners = () => {
  cache.funderButton && cache.funderButton.addEventListener('click', onFunderClick);
};

const init = ():void => {
  setupCache();
  addEventListeners();
};

export { init };
