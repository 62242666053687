import React from 'react';
import { createRoot } from 'react-dom/client';

import Time from 'components/shared/react-time';
import { init as timezoneToggleInit } from 'components/timezone-toggle/timezone-toggle';

interface CacheExpections {
  showAirdates?: HTMLElement;
  showAirdatesTimes?: NodeListOf<HTMLTimeElement>;
  showAirdatesTimezoneToggle?: HTMLButtonElement;
}

const cache: CacheExpections = {};

/**
 * Caches re-used elements.
 * @returns {function} setupCache
 */
const setupCache = () => {
  cache.showAirdates = document.querySelector('.show-airdates');
  cache.showAirdatesTimes = document.querySelectorAll('.show-airdates__time');
  cache.showAirdatesTimezoneToggle = document.querySelector(
    '.show-airdates .timezone-toggle'
  );
};

const renderTime = () => {
  const timesArray = Array.from(cache.showAirdatesTimes);
  timesArray.forEach((time) => {
    // ie11 doesn't understand .dateTime, so this falls back to a data attribute
    // this way browsers that do understand it can use it
    const dateTime = time.dateTime ? time.dateTime : time.dataset.dateTime;
    const secondaryTimezone = time.dataset.secondaryTimezone;
    const root = createRoot(time!);
    root.render(
      <Time
        dateTime={dateTime}
        secondaryTimezone={secondaryTimezone ? secondaryTimezone : null}
        className="show-airdates__time__string"
        format="ddd, MMM Do [at] LT"
      />
    );

    time.classList.add('show-airdates__time--formatted');
  });
};

/**
 * Initializes component.
 * @returns {function} _init
 */
const init = (): void => {
  setupCache();
  // this component may not be rendered, so we need to check that it exists first
  if (cache.showAirdates) {
    renderTime();
    if (cache.showAirdatesTimezoneToggle) {
      timezoneToggleInit(cache.showAirdatesTimezoneToggle);
    }
  }
};

export { init };
