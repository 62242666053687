import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'components/page-header/js/page-header';

import 'scripts/modules/external-links';
import 'components/page-footer/page-footer';
import { init as adsInit } from 'components/ad/ads';
import { init as myListButtonInit } from 'components/my-list/my-list-button/components/button-group';
import { init as showRowCarouselInit } from 'components/show-row/show-row';
import { init as contentStripEpisodesInit } from 'components/content-strip/varieties/episodes/episodes-strip';
import { init as contentStripSpecialsInit } from 'components/content-strip/varieties/specials/specials-strip';
import { init as contentStripExtrasInit } from 'components/content-strip/varieties/extras/extras-strip';
import { init as contentStripCollectionsInit } from 'components/content-strip/varieties/collections/collections-strip';
import { init as showsListInit } from 'components/franchise/shows-list/shows-list';
import { init as showAirdatesInit } from 'components/show/airdates/show';
import { init as supportingTextInit } from 'components/supporting-text/supporting-text';
import { init as showDetailHeroInit } from 'components/show/hero/hero';
import { init as fundingInit } from 'components/funding/funding';
import { init as photoGalleryInit } from 'components/photo-gallery/photo-gallery';
import { init as localizationEventsInit } from 'scripts/modules/localization-events';
import { init as getUserViewingHistoryInit } from 'scripts/modules/get-user-viewing-history';
import { init as viewingHistoryProgressInit } from 'components/viewing-history-progress/viewing-history-progress';

adsInit();
contentStripCollectionsInit();
contentStripEpisodesInit();
contentStripExtrasInit();
contentStripSpecialsInit();
fundingInit();
localizationEventsInit();
myListButtonInit();
photoGalleryInit();
showAirdatesInit();
showDetailHeroInit();
showsListInit();
showRowCarouselInit();
supportingTextInit();
getUserViewingHistoryInit();
viewingHistoryProgressInit();
