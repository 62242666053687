import React from 'react';

import { SeeAllProps } from '../../types/content-strip';

const SeeAll: React.FC<SeeAllProps> = (props) => {
  const { contentType, seeAllUrl } = props;

  return (
    <React.Fragment>
      <div className="carousel__grace-area" aria-hidden="true"></div>
      <a
        href={seeAllUrl}
        className="carousel__see-all-btn"
      >
        See All {contentType === 'more' ? 'Videos' : contentType}
      </a>
    </React.Fragment>
  );
}

export default SeeAll;