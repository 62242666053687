import React from 'react';

import MyListButton from 'components/my-list/my-list-button/components/button';
import { RelatedAsset } from 'components/video/related-asset/related-asset';
import { getStationShortCommonName } from 'scripts/utils/getStationShortCommonName';
import { PopoverProps } from './video-slides-types';

const Popover: React.FC<PopoverProps> = (props) => {
  const { video, parentVideo, userIsPassport } = props;

  const shortCommonName = getStationShortCommonName();
  const passportUrl = window.PBS_PASSPORT_URL ? window.PBS_PASSPORT_URL : `https://www.pbs.org/donate/`;
  const passportLearnMoreUrl = window.PBS_PASSPORT_LEARN_MORE_URL ? window.PBS_PASSPORT_LEARN_MORE_URL : `https://www.pbs.org/passport/learn-more/`;

  // logic for when we should toggle on the passport information
  // which is when the video is in passport and the user is not a member
  const showPassportInfo = video.flags?.is_mvod && !userIsPassport;

  return (
    <div className="popover popover--video content-strip-popover"
      data-testid="rtl-contentStripPopover">
      <p className="popover__over-title">
        {video.ancestor_slug && video.ancestor_type ? (
          <a href={`/${video.ancestor_type}/${video.ancestor_slug}/`}>
            {video.ancestor_title}
          </a>
        ) : (
          <React.Fragment>{video.ancestor_title}</React.Fragment>
        )}
      </p>
      <p className="popover__title">
        <a href={`/video/${video.slug}/`}>{video.title}</a>
      </p>
      <p className="popover__meta-data">
        {video.flags?.is_mvod
          ? <svg className="pbs-passport-compass-rose" data-testid="rtl-contentStripPopoverCompassRose">
              <use xlinkHref="/static/svg/sprite.svg#pbs-passport-compass-rose"></use>
            </svg>
          : null
        }
        {video.summary}
      </p>

      <p className="popover__description">
        {video.description_short}

        { showPassportInfo ?
          <RelatedAsset video={parentVideo} displayAsText={true} ></RelatedAsset>
          : null
        }
      </p>

      { showPassportInfo ?
      <div className="popover-video__passport-info" data-testid="rtl-contentStripPopoverPassport">
        <p className="passport-lockup">
          {shortCommonName}
          <svg className="pbs-passport-compass-rose">
            <use xlinkHref="/static/svg/sprite.svg#pbs-passport-compass-rose"></use>
          </svg>
          Passport
        </p>
        <p className="popover-video__passport-info__text">This video is currently available with {shortCommonName} Passport.</p>
        <div className="popover-video__passport-info__links">
          <a href={ passportUrl } target="_blank" rel="noopener noreferrer" className="btn btn--fill--blue an-59_1">Donate and Start Watching</a>
          <a href={ passportLearnMoreUrl } target="_blank" rel="noopener noreferrer" className="btn btn--ghost an-59_2">What is Passport?</a>
        </div>
      </div>
      : null
      }

      { !showPassportInfo ?
      <div className="popover--video__watch-buttons" data-testid="rtl-contentStripPopoverWatchButtons">
        <MyListButton
          gtmLabel={`${video.ancestor_title} | ${video.title} | ${video.cid} | ${video.video_type}`}
          cid={video.cid}
          contentType={`video`}
          slug={video.slug}
        />
        {/* Watch "Preview | Clip" loaded from episode's related asset */}
        <RelatedAsset video={parentVideo} displayAsText={false} ></RelatedAsset>
      </div>
      : null
      }
    </div>
  );
};

export default Popover;
