import FallbackImage from 'scripts/modules/fallback-image';

/**
 * Sets up an error event handler for the images passed it
 * then creates a new fallback image
 * @param {NodeList} - images - the set of images being set up with a fallback
 * @param {boolean} - textFallback - whether or not to include a text fallback
 */
const setupImageErrorHandler = (images: NodeList, textFallback: boolean): void => {
  const createFallbackImage = (e: Event) => {
    const targetImage = e.target;
    if (targetImage) {
      const fallbackImage = new FallbackImage({
        el: targetImage as HTMLImageElement,
        textFallback: textFallback ? textFallback : false,
      });

      fallbackImage.init();
    }

    targetImage.removeEventListener('error', createFallbackImage)
  }

  images.forEach(image => image.addEventListener('error', createFallbackImage));
};

export { setupImageErrorHandler }
