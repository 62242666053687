import React, { useState, useEffect, useRef, useMemo } from 'react';
import { createRoot } from 'react-dom/client';

import Cookies from 'js-cookie';

import ContentStrip from '../../content-strip';
import LoadingIndicator from 'components/shared/loading-indicator';
import { SeasonPicker } from '../../components/season-picker/season-picker';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { EpisodesStripProps, csRequest, csResponse } from '../../types/content-strip';
import { calcNumSlidesUp, createVideoSlides, getResponsiveOptions } from '../../utils/utils';
import { getSlides } from './episodes-data';

const EpisodesStrip: React.FC<EpisodesStripProps> = (props) => {
  const { context, currentContent, initialVideos, parentSeason, seasons, showSlug, videoType } = props;
  const isFirstRender = useRef(true);
  const contentType = 'episodes';
  const width: number = useWindowWidth();
  const responsiveOptions = getResponsiveOptions(contentType);
  const requestParams: csRequest = useMemo(() => {
    return {
      base: 'show', // episodes content strip always makes calls to /show/
      show_slug: showSlug,
      start: 0,
      limit: 24,
      station_id: Cookies.get('pbsol.station_id') || null,
      season_id: ''
    }
  }, [showSlug]);

  const [isFetching, setIsFetching] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [numSlidesUp, setNumSlidesUp] = useState(calcNumSlidesUp(contentType, width));
  const [selectedSeason, setSelectedSeason] = useState(parentSeason);
  const [seeAllUrl, setSeeAllUrl] = useState(`/show/${showSlug}/episodes/`);
  const [slides, setSlides] = useState([]);

  // use the initial data passed in from template for first render
  useEffect(() => {
    setIsFetching(true);
    const filteredResponse = initialVideos.content.map(item => {
      return {
        ...item,
        isCurrentContent: item.slug === currentContent,
      };
    });
    const slides = createVideoSlides(filteredResponse);
    setSlides(slides);
    setNextPageUrl(initialVideos.meta.links.next);
    setIsFetching(false);
    if (!selectedSeason) {
      setSeeAllUrl(`/show/${showSlug}/episodes/`);
    } else {
      setSeeAllUrl(`/show/${showSlug}/episodes/season/${selectedSeason}/`);
    }
  }, [currentContent, initialVideos.content, initialVideos.meta.links.next, selectedSeason, showSlug]);

  // when a season is selected, fetch the first batch of episodes for that season and add them as slides
  useEffect(() => {

    if (isFirstRender.current || seasons.length < 1) {
      isFirstRender.current = false; // first time rendering the component, we want to skip
      return;
    }
    const seasonObject = seasons.find(season => season.ordinal === selectedSeason);
    setIsFetching(true);
    getSlides(seasonObject, requestParams).then((response: csResponse) => {
      // add 'isCurrentContent' flag on video slide if we're on that video's playback page
      const filteredResponse = response.content.map(item => {
        return {
          ...item,
          isCurrentContent: item.slug === currentContent,
        };
      });
      const slides = createVideoSlides(filteredResponse);
      setSlides(slides);
      setNextPageUrl(response.meta.links.next);
      setIsFetching(false);
    });
    if (!selectedSeason) {
      setSeeAllUrl(`/show/${showSlug}/episodes/`);
    } else {
      setSeeAllUrl(`/show/${showSlug}/episodes/season/${selectedSeason}/`);
    }
  }, [selectedSeason, currentContent, requestParams, seasons, showSlug]);

  // when a change to the browser width is detected, alter the number of slides up accordingly
  useEffect(() => {
    setNumSlidesUp(calcNumSlidesUp(contentType, width));
  }, [width]);

  const fetchMoreSlides = () => {
    if (!nextPageUrl) return Promise.resolve(null);
    const newStart = new URL(nextPageUrl).searchParams.get('start');
    const newRequest = {
      ...requestParams,
      start: newStart
    }
    const seasonObject = seasons.find(season => season.ordinal === selectedSeason);
    let newSlides = null;
    newSlides = getSlides(seasonObject, newRequest).then((newResponse: csResponse) => {
      setNextPageUrl(newResponse.meta.links.next);
      return createVideoSlides(newResponse.content);
    });
    return newSlides;
  };

  let carouselTitle = 'Episodes';
  if (['extras', 'episodes'].includes(context)) {
    carouselTitle = 'More Episodes';
  }

  if (slides && slides.length > 0) {
    return (
      <ContentStrip
        carouselTitle={carouselTitle}
        contentType={contentType}
        fetchMoreSlides={fetchMoreSlides}
        isFetching={isFetching}
        numSlidesUp={numSlidesUp}
        responsiveOptions={responsiveOptions}
        season={selectedSeason}
        seeAllUrl={seeAllUrl}
        showSlug={showSlug}
        slides={slides}
        videoSlug={currentContent}
        videoType={videoType}
      >
        {/* 2 seasons means "All" and "1", i.e. just one season */}
        {seasons && seasons.length > 2 && (
          <SeasonPicker
            contentType={contentType}
            context={context}
            selectedSeason={selectedSeason}
            setSelectedSeason={setSelectedSeason}
            seasonsList={seasons}
          />
        )}
      </ContentStrip>
    )
  } else return (
    <LoadingIndicator addClass="content-strip__loading-indicator" />
  );
};

const init = (): void => {
  // Check that the DOM element exists before trying to mount the component.
  const mountPoint = document.getElementById('episodes-strip');
  const jsonNode = document.getElementById('content-strip-data');

  if (mountPoint && jsonNode) {
    // take any props from template data attributes and json_data object
    const contentStripData = JSON.parse(jsonNode.textContent);
    const props: EpisodesStripProps = JSON.parse(JSON.stringify(mountPoint.dataset));
    const episodesData = contentStripData['episodes_data']
    props.seasons = episodesData['seasons'];
    props.parentSeason = episodesData['default_season'];
    props.initialVideos = episodesData['default_season_episodes'];
    const root = createRoot(mountPoint!);
    root.render(<EpisodesStrip {...props} />);
  }
};

export { init };
