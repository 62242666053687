import React, { useState, useEffect, useRef, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import Cookies from 'js-cookie';

import ContentStrip from '../../content-strip';
import LoadingIndicator from 'components/shared/loading-indicator';
import { SeasonPicker } from '../../components/season-picker/season-picker';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { ExtrasStripProps, csRequest, csResponse } from '../../types/content-strip';
import { calcNumSlidesUp, createVideoSlides, getResponsiveOptions } from '../../utils/utils';
import { getSlides } from './extras-data';

const ExtrasStrip: React.FC<ExtrasStripProps> = (props) => {
  const { carouselTitle, context, currentContent, initialVideos, parentSeason, parentType, parentVideoSlug, seasons, seeAllUrl, showSlug, requestBase } = props;
  const isFirstRender = useRef(true);
  const contentType = 'extras';
  const width: number = useWindowWidth();
  const responsiveOptions = getResponsiveOptions(contentType);

  const requestParams: csRequest = useMemo(() => {
    return {
      base: requestBase,
      show_slug: showSlug,
      video_slug: parentVideoSlug,
      start: 0,
      limit: 24,
      station_id: Cookies.get('pbsol.station_id') || null,
      season_id: ''
    }
  }, [requestBase, showSlug, parentVideoSlug]);

  const [isFetching, setIsFetching] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [numSlidesUp, setNumSlidesUp] = useState(calcNumSlidesUp(contentType, width));
  const [selectedSeason, setSelectedSeason] = useState(parentSeason);
  const [slides, setSlides] = useState([]);
  const [seeAll, setSeeAll] = useState(seeAllUrl);

  // use the initial data passed in from template for first render
  useEffect(() => {
    setIsFetching(true);
    const filteredResponse = initialVideos.content.map(item => {
      return {
        ...item,
        isCurrentContent: item.slug === currentContent
      };
    });
    const slides = createVideoSlides(filteredResponse);
    setSlides(slides);
    setNextPageUrl(initialVideos.meta.links.next);
    setIsFetching(false);
    if (!seeAllUrl) {
      if (!selectedSeason) {
        setSeeAll(`/show/${showSlug}/extras/more/`);
      } else {
        setSeeAll(`/show/${showSlug}/extras/season/${selectedSeason}/`);
      }
    }
  }, [currentContent, initialVideos.content, initialVideos.meta.links.next, seeAllUrl, selectedSeason, showSlug]);

  // when a season is selected, fetch the first batch of extras for that season and add them as slides
  useEffect(() => {
    if (isFirstRender.current || seasons.length < 1) {
      isFirstRender.current = false; // first time rendering the component, we want to skip
      return;
    }
    let seasonObject = seasons.find(season => season.ordinal === selectedSeason);
    // sometimes, the selected season hasn't been released yet and won't be in the seasons list.
    // in those cases, just use a season we do have access to
    if (!seasonObject) {
      seasonObject = seasons[0];
      setSelectedSeason(seasonObject.ordinal);
    }
    setIsFetching(true);
    getSlides(seasonObject, requestParams).then((response: csResponse) => {
      const seasonSlides = createVideoSlides(response.content);
      setSlides(seasonSlides);
      setNextPageUrl(response.meta && response.meta.links && response.meta.links.next);
      setIsFetching(false);
    });
    if (!selectedSeason) {
      setSeeAll(`/show/${showSlug}/extras/more/`);
    } else {
      setSeeAll(`/show/${showSlug}/extras/season/${selectedSeason}/`);
    }
  }, [selectedSeason, requestParams, seasons, showSlug]);

  // when a change to the browser width is detected, alter the number of slides up accordingly
  useEffect(() => {
    setNumSlidesUp(calcNumSlidesUp(contentType, width));
  }, [width]);

  const fetchMoreSlides = () => {
    if (!nextPageUrl) return Promise.resolve(null);
    const newStart = new URL(nextPageUrl).searchParams.get('start');
    const newRequest = {
      ...requestParams,
      start: newStart
    }
    const seasonObject = seasons.find(season => season.ordinal === selectedSeason);
    let newSlides = null;
    newSlides = getSlides(seasonObject, newRequest)
      .then((newResponse: csResponse) => {
        setNextPageUrl(newResponse.meta.links.next);
        return createVideoSlides(newResponse.content);
      });
    return newSlides;
  };

  if (slides && slides.length > 0) {
    return (
      <ContentStrip
        carouselTitle={carouselTitle ? carouselTitle : "Extras"}
        contentType={contentType}
        fetchMoreSlides={fetchMoreSlides}
        numSlidesUp={numSlidesUp}
        responsiveOptions={responsiveOptions}
        season={selectedSeason}
        isFetching={isFetching}
        seeAllUrl={seeAll}
        showSlug={showSlug}
        slides={slides}
        videoSlug={parentVideoSlug}
        videoType={parentType}
      >
        {seasons && seasons.length > 1 && (
          <SeasonPicker
            contentType={contentType}
            context={context}
            selectedSeason={selectedSeason}
            setSelectedSeason={setSelectedSeason}
            seasonsList={seasons}
          />
        )}
      </ContentStrip>
    )
  } else return (
    <LoadingIndicator addClass="content-strip__loading-indicator" />
  );
};

const init = (): void => {
  // Check that the DOM element exists before trying to mount the component.
  const mountPoint = document.getElementById('extras-strip');
  const jsonNode = document.getElementById('content-strip-data');
  if (mountPoint && jsonNode) {
    // take any props from template data attributes and json_data object
    const contentStripData = JSON.parse(jsonNode.textContent);
    const props: ExtrasStripProps = JSON.parse(JSON.stringify(mountPoint.dataset));
    const extrasData = contentStripData['extras_data'];
    props.seasons = extrasData['seasons'];
    props.parentSeason = extrasData['default_season'];
    props.initialVideos = extrasData['default_season_extras'];
    props.carouselTitle = extrasData['title'];
    props.seeAllUrl = extrasData['title_link'];
    const root = createRoot(mountPoint!);
    root.render(<ExtrasStrip {...props} />);
  }
};

export { init };
