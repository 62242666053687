import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import { init as showDetailHeroCarouselInit } from './components/carousel/carousel';

type showHeroElementCache = {
  carousel?: HTMLElement;
  descriptionShort?: HTMLElement;
  descriptionButton?: HTMLButtonElement;
  descriptionLong?: HTMLElement;
  popoverTrigger?: HTMLElement;
  popoverContent?: HTMLElement;
  popoverClose?: HTMLElement;
  videos?: NodeListOf<Element>;
};

const cache: showHeroElementCache = {};
const setupCache = () => {
  // description toggling elements (below $sm only)
  cache.descriptionButton = document.querySelector(
    '.show-hero__description__button'
  );
  cache.descriptionShort = cache.descriptionButton.parentNode as HTMLElement;
  cache.descriptionLong = cache.descriptionButton.parentNode
    .nextSibling as HTMLElement;

  // description popover elements (above $sm only)
  cache.popoverTrigger = document.querySelector('.show-hero__popover-trigger');
  cache.popoverContent = document.querySelector('.show-hero__popover');
  cache.popoverClose = document.querySelector('.show-hero__popover-close');
  // new react-based carousel
  cache.carousel = document.getElementById('show-hero-carousel');
};

// replace short description and "MORE" button with long description
const onDescriptionClick = () => {
  cache.descriptionShort.remove();
  cache.descriptionLong.classList.remove('is-hidden');
};

const addEventListeners = () => {
  if (cache.descriptionButton) {
    cache.descriptionButton.addEventListener('click', onDescriptionClick);
  }
};

const setupDescriptionPopover = () => {
  if (cache.popoverTrigger && cache.popoverContent) {
    tippy(cache.popoverTrigger, {
      allowHTML: true,
      content: cache.popoverContent,
      delay: [100, 100],
      interactive: true,
      maxWidth: 'none',
      onCreate(instance) {
        const closeButton: HTMLElement = cache.popoverContent.querySelector(
          '.show-hero__popover-close'
        );
        closeButton.addEventListener('click', () => instance.hide());
      },
      placement: 'right',
      theme: 'light',
      touch: false,
      trigger: 'click',
    });

    cache.popoverContent.classList.remove('is-hidden');
  }
};

const init = (): void => {
  setupCache();
  addEventListeners();
  setupDescriptionPopover();
  if (cache.carousel) {
    showDetailHeroCarouselInit();
  }
};

export { init };
