import React from 'react';

import { Season, SeasonSelectProps } from '../../types/content-strip';

export const SeasonSelect: React.FC<SeasonSelectProps> = (props) => {
  const { alsoDisplayingButtons, contentType, selectedSeason, seasonsList, handleSeasonChange, uniqueId } = props;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSeason = parseInt(event.target.value, 10);

    handleSeasonChange(newSeason); // update the season in parent component
  }

  if (!seasonsList) return null;

  // These flags determine whether or not the "More" option is set to disabled or selected
  const isDisabled = seasonsList.filter(season => season.ordinal === selectedSeason).length > 0;
  const isSelected = !isDisabled;

  return (
    <>
      <label className="visuallyhidden" htmlFor={`season-select--${contentType}`}>
        Select a season
      </label>
      <select
        className="content-strip__season-select"
        onChange={handleChange}
        name="season-select"
        id={`${uniqueId}-season-select--${contentType}`}
        value={isSelected ? "more" : selectedSeason}
        data-testid="rtl-seasonSelect"
      >
        {alsoDisplayingButtons ? <option value="more" disabled={isDisabled} className="season__more">More</option> : null }
        {seasonsList.map((season: Season, index: number) => {
          let seasonName = `Season ${season.ordinal}`;
          if (!season.ordinal && contentType === 'extras') {
            seasonName = 'More Extras';
          } else if (!season.ordinal && contentType === 'episodes') {
            seasonName = 'All'
          }
          return (
            <option key={index} value={season.ordinal}>
              {seasonName}
            </option>
          )
        })}
      </select>
    </>
  );
};
