import React from 'react';

import MyListButton from 'components/my-list/my-list-button/components/button';
import { ReactComponent as TitleCaretRight } from 'svg/pbs-right-caret.svg';
import { SingleSpecialProps } from '../../types/content-strip';

const SingleSpecial: React.FC<SingleSpecialProps> = (props) => {
  const { featuredVideo } = props;
  const { slug, cid, title, image, flags, description_short, summary, ancestor_slug, ancestor_title, video_type } = featuredVideo;

  const truncate = (input: string) =>
    input.length > 100 ? `${input.substring(0, 100)}...` : input;
  const truncatedTitle = truncate(title);

  return (
    <div className="single-special">
      <h2 className="single-special__content-section-title content-section-title" id="specials">
        <a
          href={`/show/${ancestor_slug}/specials/`}
          className="content-section-title__link"
          data-gtm-action="Specials"
        >
          Specials
          <TitleCaretRight />
        </a>
      </h2>

      <div className="single-special__content">
        <a
          href={`/video/${slug}/`}
          data-gtm-action={`${title}`}
          data-gtm-position="1"
          className="single-special__image-link has-play-icon fallback-image__container fallback-image__container--gray"
        >
          <img
            className="single-special__image image--needs-fallback"
            sizes={`(max-width: 767px) 100vw,
                (max-width: 1023px) calc(58vw - 48px),
                (max-width: 1279px) calc(66vw - 48px),
                800px`}
            srcSet={`${image}?crop=280x157&format=auto 280w,
                ${image}?crop=480x270&format=auto 480w,
                ${image}?crop=767x432&format=auto 767w,
                ${image}?crop=800x450&format=auto 800w,
                ${image}?crop=960x540&format=auto 960w,
                ${image}?crop=1534x864&format=auto 1534w,
                ${image}?crop=1600x900&format=auto 1600w`}
            src={`${image}?crop=800x450&format=auto`}
            alt={`${title}`}
            loading="lazy"
          />
          {flags.is_mvod && (
            <img
              className="pbs-passport-compass-rose"
              src={`${window.PBS_STATIC_URL}svg/pbs-passport-compass-rose--corner.svg`}
              alt="Passport"
              width="32"
              height="32"
            />
          )}
        </a>
        <div className="single-special__details">
          <h3 className="single-special__title">
            <a
              href={`/video/${slug}/`}
              data-gtm-action={title}
              data-gtm-position="1"
              className="single-special__title-link"
            >
              {truncatedTitle}
            </a>
          </h3>
          <p className="single-special__description">
            {description_short}
          </p>
          <p className="single-special__meta-data">
            {summary}
          </p>
          <div className="single-special__buttons">
            <MyListButton
              gtmLabel={`${ancestor_title} | ${title} | ${cid} | ${video_type}`}
              cid={cid}
              contentType={`video`}
              slug={slug}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleSpecial;
