import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import ContentStrip from '../../content-strip';
import LoadingIndicator from 'components/shared/loading-indicator';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { CollectionsStripProps } from '../../types/content-strip';
import { calcNumSlidesUp, createCollectionSlides, getResponsiveOptions } from '../../utils/utils';

const CollectionsStrip: React.FC<CollectionsStripProps> = (props) => {
  const { initialCollections, showSlug } = props;
  const carouselTitle = initialCollections['title'] || 'Collections';
  const seeAllUrl= initialCollections['title_link'] || `/show/${showSlug}/collections/`;
  const contentType = 'collections';
  const width: number = useWindowWidth();
  const responsiveOptions = getResponsiveOptions(contentType); // defines 2-up/3-up/4-up layout breakpoints

  const [isFetching, setIsFetching] = useState(false);
  const [slides, setSlides] = useState([]);
  const [numSlidesUp, setNumSlidesUp] = useState(calcNumSlidesUp(contentType, width)); // gets the number of slides up based on responsiveOptions and initial width

  // on initial load, create collection slides
  useEffect(() => {
    setIsFetching(true);
    const slides = createCollectionSlides(initialCollections);
    setSlides(slides);
    setIsFetching(false);
  }, [initialCollections]);

  // when a change to the browser width is detected, alter the number of slides up accordingly
  useEffect(() => {
    setNumSlidesUp(calcNumSlidesUp(contentType, width));
  }, [width]);

  if (slides && slides.length > 0) {
    return (
      <ContentStrip
        carouselTitle={carouselTitle}
        contentType={contentType}
        fetchMoreSlides={() => Promise.resolve(null)}
        numSlidesUp={numSlidesUp}
        responsiveOptions={responsiveOptions}
        isFetching={isFetching}
        seeAllUrl={seeAllUrl}
        showSlug={showSlug}
        slides={slides}
        videoSlug={null}
        videoType={null}
      />
    )
  } else return (
    <LoadingIndicator addClass="content-strip__loading-indicator" />
  );
};

const init = (): void => {
    // Check that the DOM element exists before trying to mount the component.
  const mountPoint = document.getElementById('collections-strip');
  const jsonNode = document.getElementById('content-strip-data');
  if (mountPoint && jsonNode) {
    // take any props from template data attributes and json_data object
    const contentStripData = JSON.parse(jsonNode.textContent);
    const props: CollectionsStripProps = JSON.parse(JSON.stringify(mountPoint.dataset));
    props.initialCollections = contentStripData['collections_data'];
    const root = createRoot(mountPoint!);
    root.render(<CollectionsStrip {...props} />);
  }
};

export { init };
