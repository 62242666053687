import React, { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { Splide } from '@splidejs/react-splide';

import { isViewportOver } from 'scripts/utils/isViewportOver';
import LoadingIndicator from 'components/shared/loading-indicator';
import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { VideoSlide } from 'components/shared/video-slide'
import { Video } from 'components/shared/video-slides-types'
import { Splide as SplideType } from '@splidejs/splide';

type CarouselProps = {
  slug: string;
  isFranchise: boolean;
}

type showCarouselContinueWatchingResponse = {
  videos: Array<Video>;
  collection_based_on_profile: 'true' | 'false';
}

const shouldDisplayContinueWatching = (collectionBasedOnProfile: boolean, index: number): boolean => {
  // only show continue watching if the collection is based on the user profile
  // and the video is the first one in the carousel
  return collectionBasedOnProfile && index === 0;
}

const percentageWatchedToDisplay = (video: Video): number | null => {
  if (video.seconds_watched > 0) {
    return Math.round((video.seconds_watched / video.duration) * 100);
  } else {
    return null;
  }
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const { slug, isFranchise } = props;

  const [isFetching, setIsFetching] = useState(true);
  const [collectionBasedOnProfile, setCollectionBasedOnProfile] = useState(false);
  const [videos, setVideos] = useState([]);
  const splideRef = useRef(null);

  useEffect(() => {
    setIsFetching(true);
    const showOrFranchise = isFranchise ? 'franchise' : 'show';

    let url = `/${showOrFranchise}/${slug}/carousel-videos/`;
    if (window.PBS_COUNTRY_ID) {
      url = `${url}?country_id=${window.PBS_COUNTRY_ID}`;
    }
    const fetchShowCarouselData = () => {
      fetch(url, {
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }).then((response) => {
        if (response.status !== 200) {
          throw new Error('bad server resonse');
        }
        return response.json();
      }).then((json: showCarouselContinueWatchingResponse) => {
        setVideos(json.videos);
        setCollectionBasedOnProfile(json.collection_based_on_profile === 'true');
        setIsFetching(false);
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching carousel data: ', error);
        // hide the loading indicator
        setIsFetching(false);
      });
    }

    fetchShowCarouselData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateSplideOptions = (numSlides: number) => {
    const options = {
        arrowPath: arrowPath, // using our custom caret SVG in splide's built-in arrows
        arrows: true,
        drag: true,
        gap: '0.8rem',
        mediaQuery: 'min',
        padding: '6.4rem',
        pagination: true,
        perPage: 1,
        type: 'loop',
        // arranged by min-width
        breakpoints: {
          768: {
            arrows: false,
            clones: 0,
            gap: '1.6rem',
            padding: '0rem',
            pagination: false,
            perPage: 3,
          },
          1280: {
            gap: '5%',
          }
        },
        classes: {
          arrow: 'carousel__arrow',
          prev: 'splide__arrow--prev carousel-prev',
          next: `splide__arrow--next carousel-next`,
        },
      }

      if (numSlides === 1) {
        return {
          ...options,
          arrows: false,
          clones: 0,
          padding: '1.6rem',
          pagination: false,
        }
      }

      return options;
  }

  return isFetching ? (
    <LoadingIndicator addClass="content-strip__loading-indicator" />
    ) : (
    <Splide
      onResized={(splide: SplideType) => {
        // handles the case where the carousel starts at mobile
        // but goes above mobile - at that point we want to put
        // the first slide back in the first position
        if (splide.index !== 0 && isViewportOver(767)) {
          splide.go(0);
        }
      }}

      options={generateSplideOptions(videos.length)}
      ref={splideRef}
    >
      {videos.map((video: Video, index: number) => (
        <VideoSlide
          video={video}
          includeOverTitle={false}
          key={video.slug}
          className="show-hero-carousel__slide"
          displayContinueWatching={shouldDisplayContinueWatching(collectionBasedOnProfile, index)}
          percentageWatched={percentageWatchedToDisplay(video)} />
      ))}
    </Splide>
  )
}

const init = (): void => {
    // Check that the DOM element exists before trying to mount the component.
  const mountPoint = document.getElementById('show-hero-carousel');
  if (mountPoint) {

    const props: CarouselProps = {
      'slug': mountPoint.dataset.slug,
      'isFranchise': mountPoint.dataset.franchise === 'true',
    };

    const root = createRoot(mountPoint!);
    root.render(<Carousel {...props} />);
  }
};

export { init, shouldDisplayContinueWatching, percentageWatchedToDisplay, Carousel };
